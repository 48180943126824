import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { setIsMenuHidden, setIsSubPage, setPageTitle } from '../redux/slices/pageTitle';
import useLocales from './useLocales';
import { isSnakeCase, toSnakeCase } from '../utils/formatString';
import { PATH_LOAN } from '../routes/paths';

function usePageTitle({ title, isSubPage = false, isTranslate = true, hideMenu = null }) {
  const { translate } = useLocales();

  useEffect(() => {
    if (title) {
      let newTitle = title;

      if (isTranslate) newTitle = translate(toSnakeCase(title));

      setPageTitle(isSnakeCase(newTitle) ? title : newTitle);
    }
    setIsSubPage(isSubPage);
    if (hideMenu == null && isSubPage === true) setIsMenuHidden(true);
    if (hideMenu !== null) setIsMenuHidden(hideMenu);
    return () => {
      setPageTitle('');
      setIsSubPage(false);
      setIsMenuHidden(false);
    };
  }, [hideMenu, isSubPage, isTranslate, title, translate]);

  return title;
}

export default usePageTitle;
