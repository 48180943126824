// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const cn = {
  dashboard: '首页',
  my_loans: '我的贷款',
  notification: '通知',
  wait_while_profile_being_verified: '请稍候，您的个人资料正在验证中。',
  account: '账户',
  chat: '聊天',
  online_bank_account: '网上银行账户',
  x_is_required: '{{x}} 是必需填的',
  bank: '银行',
  account_number: '银行账户号码',
  x_must_be_y_to_z_digits_in_length: '{{x}} 的长度必须是 {{y}}-{{z}} 个数字',
  payment: '支付',
  total_payment: '总付款',
  wrong_otp: '错误的OTP',
  refer_a_friend: '推荐朋友',
  apply_loan_details: '申请贷款详情',
  my_listing: '我的列表',
  edit_profile: '编辑个人资料',
  extra_documents: '额外文件',
  employer_name: '雇主名称',
  employer_contact_number: '雇主联系电话',
  upload_payslip_for_last_month: '上传上个月的工资单',
  upload_bank_statement_salary_last_month: '上传上个月工资银行对账单',
  upload_utility_bill_last_month: '上传上个月的水电费账单',
  upload_active_mortgage_statement: '上传有效的抵押声明',
  dont_worry_your_data_is_kept_protected: '别担心，您的数据是依法保存的，受我们保护！',
  nric_front: '身份证正面',
  upload_nric_front: '上传身份证正面',
  nric_back: '身份证背面',
  upload_nric_back: '上传身份证件',
  nric_number: '身份证号码',
  complete_profile_to_submit_loan_application: '请填写您的个人资料以提交贷款申请。',
  name_as_per_ic: '身份证名字',
  view_example: '查看例子 >',
  upload_video_selfie_together_with_nric_x_appname:
    '上传自拍和身份证一起的视频, 自拍视频,拿着你的身份证说明要申请和 {{x}} 贷款',
  emergency_contact_1: '紧急联系人 1',
  emergency_contact_2: '紧急联系人 2',
  relationship: '关系',
  name: '名称',
  done: '完成',
  data_is_being_prep: '数据正在准备中',
  loan_application_failed: '贷款申请失败！',
  title_: '标题: ',
  message_: '信息: ',
  contact_support: '联系客服',
  ok: 'Ok',
  receive_method: '收款方法',
  select_bank: '选择银行',
  key_in_account_number: '输入银行账户号码',
  congratulations: '恭喜！',
  your_loan_has_been_approved: '您的贷款已\n批准！',
  payment_amount_xcurrencyx: '支付金额（{{x}}）',
  interest: '兴趣',
  underwriting_fee: '承保费',
  gateway_fee: '网关费用',
  service_fee: '服务费',
  deposit: '存款',
  total_amount_received: '收到的总金额',
  apply_now: '立即申请',
  application_submitted_wait_pic_to_serve_u: '您的申请已成功提交。请等待我们的负责人为您服务。',
  mobile_number: '手机号码',
  _due_: ' 截止: ',
  pay_now: '立即付款',
  recent_transactions: '近期交易',
  no_loan_yet: '还没有贷款',
  enter_any_payment_amount: '输入任何支付金额',
  select_payment_method: '选择支付方式',
  invite_your_friend_to_x_app: '邀请您的朋友加入 {{x}} 应用！',
  about_x: '关于 {{x}}',
  upload_receipt_x_will_verify_payment: '请上传ATM/现金存款收据图片，{{x}}将在3个工作日内验证您的付款。',
  pay_to_either_one_of_x_accounts: '使用ATM或现金存款向以下 {{x}} 的任一账户付款：',
  youre_one_step_away_x_click_next_to_proceed: '您现在离享受 {{x}} 的好处只有一步之遥。点击下一步继续。',
  welcome_to_x: '欢迎来到 {{x}}',
  share_now: '立即分享',
  copy: '复制',
  your_referral_history: '您的推荐历史',
  share_your_referral_code: '分享您的推荐代码',
  how_referral_works: '推荐如何运作',
  copied: '已复制',
  how_referral_works_x_y_commission:
    '我们的佣金公式允许推荐人分别从受邀者的第一笔贷款和后续贷款中获得 {{x}} 和 {{y}}。当被邀请人完成全额还款后，这些佣金将记入推荐人。',
  customer_support: '客服',
  date: '日期',
  amount: '金额',
  status: '状态',
  enter_payment_amount: '输入支付金额',
  entered_amount_more_than_you_owe: '输入的金额超过了您的欠款。',
  oops_we_are_sorry: '糟糕，我们很抱歉',
  more_time_needed_will_inform_once_completed: '需要更多时间，完成后我们会通知您。',
  contact_us: '联系我们',
  proceed_to_online_banking_to_pay_loan: '进入网上银行还款',
  proceed: '继续',
  thank_you_for_payment: '感谢您的付款！',
  please_register_an_account: '请注册一个账号',
  referral_code_optional: '推荐代码（非必要）',
  register: '注册',
  downline_name: '下线名称',
  no_: '号码',
  total_commission: '总佣金',
  total_withdrawal: '总提款',
  balance: '余额',
  total_register_: '总注册：',
  total_loans_: '总贷款：',
  upload_your_receipt: '上传收据',
  email_address: '电子邮件地址',
  password: '密码',
  forget_password: '忘记密码',
  sign_in: '登录',
  upload_photo: '上传照片',
  title: '标题',
  edit: '编辑',
  delete: '删除',
  view: '查看',
  no: '否',
  yes: '是',
  phone_number: '电话号码',
  save: '保存',
  forgot_password: '忘记密码',
  submit: '提交',
  please_verify_account_first: '请先验证账户',
  are_you_ready_to_nbe_a_part_of_the_family: '你准备好\n成为家庭的一员了吗？',
  full_name: '全名',
  verify_account: '验证账户',
  skip: '跳过',
  next: '下一个',
  let_s_get_started: '让我们开始吧',
  please_enter_otp: '请输入发送到您号码的OTP',
  new_password: '新密码',
  please_enter_your_nnew_password_to_reset_your_password: '请输入您的\n新密码以重置您的密码',
  confirm_password: '确认密码',
  reset_password: '重置密码',
  welcome_back_yo_ve_nbeen_missed: '欢迎您回来\n我们想念您了！',
  let_s_sign_you_in: '请您登录',
  choose_your_language: '选择您的语言开始！',
  login_now: '现在登录。',
  sign_up: '注册',
  log_in: '登录',
  empty_field_found: '空的',
  invalid_x: '错误的 {{x}}',
  invalid_nric_please_enter_x_format: '身份证格式错误。请输入 {{x}}',
  something_went_wrong_x: '出了点问题：{{x}}',
  something_went_wrong_pls_contact_support: '出了点问题。请联系支持。',
  enter_valid_mobile_no: '输入有效手机号',
  record_already_exists: '记录已经存在',
  x_already_exists: '{{x}} 已经存在！',
  permission_turned_off_can_be_enabled_in_app_settings: '您似乎已关闭此功能所需的权限。可以在App设置下开启！',
  go_to_settings: '转到设置',
  cancel: '取消',
  payment_info: '支付信息',
  no_amount_and_id_found: '找不到金额和ID',
  name_of_correspondence: '通讯人名称',
  number_of_correspondence: '通讯人号码',
  emergency_contact_1_2_x_shouldnt_be_the_same: '紧急联系人1和2的 %s 不应该相同',
  referral_history: '推荐历史',
  withdrawal_history: '提现历史',
  loan_packages: '贷款配套',
  ensure_settle_payment_b4_due_date_to_avoid_penalty: '确保您在到期日之前结清您的付款，以避免支付罚款',
  period: '为期',
  identity_nverification: '身份\n验证',
  threemin_nreview: '3分钟\n审查',
  threemin_nreceival: '3分钟\n收款',
  nric_name: '身份证名字',
  _days: ' 天',
  details: '详情',
  announcements: '公告',
  no_internet_connection: '没有网络',
  retry: '重试',
  code_sent: 'OTP 已发送',
  otp_verification_failed: 'OTP 验证失败',
  invalid_credential: '无效凭证',
  firebase_quota_exceeded: 'OTP 配额超出',
  wrong_otp_code: 'OTP 错误',
  too_many_request_detected: '检测到的请求过多，请稍后再试',
  account_submitted: '账户信息已提交',
  loan_application_submitted: '已提交贷款申请',
  please_wait_our_admin_to_contact_you_soon: '请等待我们的管理员尽快与您联系。',
  whats_app_no: 'WhatsApp 号码',
  invite_message_x_referral_code_y: '嘿，我找到了一个零利率贷款应用程序 {{x}} 我的推荐码是 {{y}}',
  nric_format_name: 'MyKad 身份证号码（无破折号）',
  nrc_min_length_x: '最小长度为 {{x}}',

  // <!--  Premloan new strings 04/01/2023  -->
  login_note: '登录以发现我们应用程序的精彩功能',
  or_register_now: '<u>或立即注册</u>',
  register_your_phone_number: '注册您的电话号码',
  register_note: '您的电话号码是安全的。 我们不会与任何人分享您的资料',
  same_number: '我的 WhatsApp 号码与我的电话号码相同',
  already_have_account: '已经有账户？按这里登录',
  welcome: '欢迎',
  to_x: '来到 {{x}}',
  or: '或者',
  enter_loan_amount: '输入贷款金额',
  minimum_amount: '最低金额:',
  maximum_amount: '最高金额:',
  apply_loan_note: '保持您的帐户活跃，建立信任并按时偿还贷款以增加您的限额',
  apply_online: '节省时间和钱，现在就在线提交申请吧！',
  fast_and_easy_application: '快速和简便的申请步骤和资金转账',
  data_is_safe: '您的个人数据在我们这里是安全的',
  no_notification: '您没有任何新通知！',
  refer_your_friends: '推荐你的朋友！',
  otp_sent: '我们已将 OTP 发送到您的电话号码',
  loan_amount_between_x_and_y: '贷款金额必须介于 {{x}} 和 {{y}} 之间',
  approved: '已批准',
  pending_approval: '待批准',
  select_country_region: '选择国家/地区',
  request_contact_list_permission_reason:
    '我们需要您的联系人列表以帮助检测和防止欺诈。 通过将您的联系人与我们的数据库进行比较，我们可以识别任何可疑活动并帮助保护您的帐户和个人信息的安全。',
  permission_request: '权限请求',
  app_wont_work_without_permission: '没有这些权限，我们应用程序将无法运行：',
  go_to_app_settings_allow_permission_manually: '请到应用程序设置并手动允许这些权限',
  allow: '允许',
  take_photo_using_camera: '使用相机拍照',
  select_photo_from_gallery: '从图库中选择照片',
  select_pdf_file: '选择 PDF 文件',
  failed_to_attach_photo: '附加照片失败',
  signup_agree_privacy_policy: '注册即表示您同意我们的隐私政策。',
  login_agree_privacy_policy: '登录即表示您同意我们的隐私政策。',
  request_upload_photo_permission_reason:
    '我们需要您的许可才能将照片从您的设备安全地上传到我们的服务器，以供KYC认证。 未经您的明确同意，我们不会与任何第三方分享您的照片。',
  upload_failed_please_try_again: '上传失败。 请再试一次',
  loan_payment_failed: 'Loan Payment Failed',
};

export default cn;
