import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
// @mui
import { Box } from '@mui/material';
import usePageTitle from '../hooks/usePageTitle';

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = '', isSubPage = false, isTranslate = true, hideMenu = null, meta, ...other }, ref) => {
  usePageTitle({title, isSubPage, isTranslate, hideMenu});
  return (
    <>
      <Helmet>
        <title>{`${title}`}</title>
        {meta}
      </Helmet>

      <Box ref={ref} {...other}>
        {children}
      </Box>
    </>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  isSubPage: PropTypes.bool,
  isTranslate: PropTypes.bool,
  hideMenu: PropTypes.bool,
  meta: PropTypes.node,
};

export default Page;
