import React from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// config
import { HEADER } from '../../config';

//
import DashboardHeader from './header';

import ComingSoon from '../../pages/ComingSoon';
import NavbarHorizontal from './navbar/NavbarHorizontal';
import { useSelector } from '../../redux/store';

// ----------------------------------------------------------------------

const MainStyle = styled('main')(({ theme }) => ({
  overflowY: 'auto',
  overflowX: 'hidden',
  flexGrow: 1,
  // paddingTop: HEADER.MOBILE_HEIGHT + 5,
  // paddingBottom: theme.spacing(1),
  // paddingLeft: 10,
  // paddingRight: 10,
}));
// ----------------------------------------------------------------------

const DashboardLayout = () => {
  const isDesktop = useResponsive('up', 'lg');

  const { hideMenu } = useSelector((x) => x.pageTitle);

  if (isDesktop) return <ComingSoon />;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <img
        src={require('../../assets/main_bg.png')}
        alt="BG"
        style={{ position: 'absolute', zIndex: -1, width: '100%', height: '100%' }}
      />
      <DashboardHeader />

      <MainStyle>
        <Outlet />
      </MainStyle>

      {!hideMenu && <NavbarHorizontal />}
    </Box>
  );
};

export default DashboardLayout;
