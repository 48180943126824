import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  title: '',
  isSubPage: false,
  hideMenu: false
};

const slice = createSlice({
  name: 'pageTitle',
  initialState,
  reducers: {
    setTitle(state, action) {
      state.title = action.payload;
    },
    setSubPage(state, action) {
      state.isSubPage = action.payload;
    },
    setHideMenu(state, action) {
      state.hideMenu = action.payload;
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setTitle, setSubPage, setHideMenu } = slice.actions;

export function setPageTitle(title) {
  dispatch(setTitle(title || ''));
}

export function setIsSubPage(value) {
  dispatch(setSubPage(value));
}

export function setIsMenuHidden(value) {
  dispatch(setHideMenu(value));
}
